import React from 'react';
import { ProjectMainLayout } from 'layouts/ProjectMainLayout';
import { PROJECTS } from 'constants/projects';
import { graphql, useStaticQuery } from 'gatsby';
import { SchneiderGrid } from 'components/SchneiderGrid';

export const SchneiderView = () => {
    const images = useStaticQuery(graphql`
        query SchneiderImages {
            left: file(name: { eq: "schneider-left" }) {
                childImageSharp {
                    gatsbyImageData(quality: 100)
                }
            }
            right: file(name: { eq: "schneider-right" }) {
                childImageSharp {
                    gatsbyImageData(quality: 100)
                }
            }
            desktop: file(name: { eq: "schneider-desktop" }) {
                childImageSharp {
                    gatsbyImageData(quality: 100, placeholder: NONE)
                }
            }
        }
    `);

    return (
        <ProjectMainLayout projectName={PROJECTS.schneider.name}>
            <SchneiderGrid {...images} projectName={PROJECTS.schneider.name} />
        </ProjectMainLayout>
    );
};
