import React from 'react';
import styled from 'styled-components';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';

const SGrid = styled.div`
    height: 100vh;
    display: flex;

    @media (max-width: 768px), (orientation: portrait) {
        flex-direction: column;
        height: unset;
    }
`;

const SRight = styled.div`
    margin-left: 0.8rem;
    flex: 1;
    display: flex;
    flex-direction: column;
    position: relative;

    @media (max-width: 768px), (orientation: portrait) {
        margin-left: unset;
        margin-top: 0.8rem;
        max-height: 18.75rem;
    }
`;

const SLeft = styled.div`
    display: flex;
    flex-direction: column;
    width: 63%;

    @media (max-width: 768px), (orientation: portrait) {
        width: 100%;
    }
`;

const SImageWrapper = styled.div`
    position: absolute;
    top: 40%;
    left: -20%;
    max-width: 100%;

    @media (max-width: 768px), (orientation: portrait) {
        bottom: 20%;
        top: unset;
        left: 5%;
        width: 90%;
    }
`;

const SImage = styled(GatsbyImage)`
    flex: 1;
`;

export const SchneiderGrid = ({ right, left, desktop, projectName }) => {
    return (
        <SGrid>
            <SLeft>
                <SImage image={getImage(left)} alt={projectName} width="100%" height="100%" />
            </SLeft>
            <SRight>
                <SImage image={getImage(right)} alt={projectName} width="100%" height="100%" />
                <SImageWrapper>
                    <GatsbyImage
                        image={getImage(desktop)}
                        alt={projectName}
                        layout="constrained"
                        width="300px"
                        height="150px"
                    />
                </SImageWrapper>
            </SRight>
        </SGrid>
    );
};
